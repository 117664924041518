.space-left {
  margin-left: 20px;
}

.space-right {
  margin-right: 20px;
}

.step-container {
  width: 100%;
  margin-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.page-title-text {
  color: #444b54;
  font-weight: bold;
  font-size: 1.3em !important;
  margin-bottom: 0px !important;
}

.page-subtitle {
  margin-bottom: 40px !important;
}

.no-bottom {
  margin-bottom: 0px !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.detail-title-text {
  color: #004083;
  font-weight: bold;
  font-size: 1.3em !important;
}

.files-name {
  color: #444b54;
  font-weight: bold;
  font-size: 0.9em !important;
}

.step-field.error {
  color: #f10504;
}

.files-name.error {
  color: #f10504;
}

.btn-grp-h {
  display: flex;
  width: 30%;
}

.react-select {
  margin-bottom: 0px !important;
}

.react-select .react-select__control {
  border-radius: 0px !important;
  border: none !important;
}

.step-form {
  margin-bottom: 40px;
}

.step-field-title {
  color: #444b54;
  font-weight: bold;
  font-size: 1em;
}

.step-chk-btn {
  cursor: pointer;
  margin: 10px 0;
}

.step-checkmark {
  margin-right: 10px;
  width: 18px;
  height: auto;
  object-fit: contain;
}

.matricule-container {
  display: flex;
  align-items: center;
}

.matricule-letter {
  width: 50px;
  margin-left: 8px;
  margin-right: 8px;
}

.formule-title {
  color: #009ee4;
  font-weight: 600;
  font-size: small;
}

.packs-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pack-view-container {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pack-select-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.packImg {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-bottom: 10px;
  margin-top: 20px;
}

.pack-garantie {
  display: flex;
  margin: 5px 10px;
}

.g-checked {
  width: 20px;
  height: auto;
  object-fit: contain;
  margin-right: 8px;
}

.g-select {
  cursor: pointer;
}

.files-container {
  display: flex;
  align-items: center;
  min-height: 60px;
}

.files-content {
  display: flex;
  min-width: 250px;
}

.cession-file {
  width: 300px;
  color: #444b54;
  font-weight: bold;
  font-size: 0.9em !important;
}

.cession-file .download {
  cursor: pointer;
  color: #000000;
  text-decoration: underline;
}

.add-file {
  cursor: pointer;
}

.add-btn {
  width: 25px;
  height: auto;
  object-fit: contain;
  margin: 10px 10px;
}

.pack-remove {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.photo-preview {
  width: 80px;
  display: flex;
}

.pack-photo-remove {
  width: 10px;
  height: auto;
  object-fit: contain;
  position: relative;
  bottom: 20px;
}

.devis-success {
  width: 40%;
  height: auto;
  object-fit: contain;
}

.garantie-formule-select {
  margin-left: 35px;
  margin-top: 5px;
}

.formule-description {
  color: #80868e;
  font-size: 1em;
}

.other-user-explain {
  width: 200px;
  font-size: 0.8em;
  text-align: center;
  margin-bottom: 10px;
}

.assistance-view {
  width: 50%;
}

.popover-bd {
  display: flex;
  flex-direction: column;
}

.popover-g-name {
  margin-bottom: 20px;
  color: #004188;
  font-weight: bold;
}

.popover-g-desc {
  margin-bottom: 5px;
}

.vv-table {
  width: 400px;
  height: auto;
  object-fit: contain;
}

// .custom-input.matriculeLast {
//   width: 50px;
// }
