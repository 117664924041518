.login-content {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.signin {
  @media screen and (max-width: 906px) {
    .bg-image {
      background-size: cover;
      height: 80vh;
    }
  }

  .bg-image {
    background-image: url(../../../../assets/img/auth_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-login {
    max-width: 400px !important;
  }
  .Copyright {
    position: absolute;
    bottom: 73px !important;
    left: 150px !important;
  }
  .certification {
    bottom: 60px !important;
    position: absolute;
    left: 43px;
  }
  @media screen and (max-width: 700px) {
    // .certification {
    //   bottom: 50px !important;
    //   position: absolute;
    //   right: 30px !important;
    // }
    .Copyright {
      bottom: 60px !important;
    }
  }
  .card-footer {
    padding: 0 !important;
    background-color: transparent !important;
    border-top: none !important;
  }
}

.content-footer {
  height: 25vh;
  width: 90%;
  margin-left: 5% !important;
  margin-right: 5% !important;
  margin-bottom: 2% !important;
  border-width: 1px;
  background-color: #fff !important;
  border-color: #bbbbbb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.on-secondary {
  color: white;
  font-weight: 600;
}

h2,
h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

h6 {
  font-weight: 400;
  text-transform: none;
}

.bottom-cgu {
  max-width: 380px;
}

.other-actions {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom-action {
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
}

.bottom-action:hover {
  text-decoration: underline;
  color: #037cc4;
}

.password-input {
  position: relative;
  cursor: pointer;
}

.input-addon {
  position: absolute;
  width: 20px;
  height: auto;
  object-fit: contain;
  right: 10px;
  bottom: 15px;
}

.auth-title {
  font-size: 1.8em !important;
}
