/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.landing-navbar {
  width: 100% !important;
  min-height: 80px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8 !important;
  position: fixed;
  z-index: 10;
}

@media (max-width: 500px) {
  .landing-navbar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-logo {
    width: 15%;
  }
}

.app-logo {
  width: 12%;
  height: auto;
  cursor: pointer;
}

.topbar-right {
  display: flex;
  min-width: 90px;
}

.nav-right-item {
  height: 40px;
  min-width: 90px;
  padding: 5px 5px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* font-family: Monserrat; */
  font-weight: 700;
}

.nav-right-item:hover {
  background-color: #f8f8f8;
}

.app-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}

.app-content {
  width: 95vw;
  min-height: 80vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #bbbbbb;
  align-self: center;
  margin-top: 15vh;
  margin-bottom: 20px;
  /* overflow: auto; */
}

.app-loader {
  width: 95vw;
  height: 80vh;
  background: #ffffff;
  align-self: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-black {
  color: #444b54;
}

.text-bold {
  font-family: Avenir;
  font-weight: 700;
}

.text-center {
  display: flex;
  justify-content: center;
}

.auth-error {
  margin-top: 10px;
  font-weight: normal;
  text-transform: none;
  text-align: center;
}

.menu-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.home-navlink {
  color: #373737 !important;
  text-transform: none;
  font-size: 15px;
}

.app-name {
  color: #373737;
  font-size: 15px;
}

.back-icon {
  width: 15px;
  height: auto;
}

.btn-shadow {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 24px -8px rgba(0, 0, 0, 0.75);
}

.creation-date-input,
.custom-input {
  background-color: rgba(159, 157, 157, 0.29) !important;
  border: 0px solid rgba(248, 248, 248, 0.7) !important;
  border-radius: 2px !important;
  height: 50px !important;
  width: 100% !important;
  color: #373737 !important;
  font-size: 1.05em !important;
  font-weight: bold !important;
  display: flex;
  align-items: center;
}

.creation-date-input.error,
.custom-input.error {
  background-color: rgba(199, 36, 36, 0.2) !important;
  border: 1px solid rgba(199, 36, 36, 1) !important;
}

.custom-area {
  height: 100px !important;
}

/* .creation-date-input {
  text-align: end;
  margin-right: 10px;
} */

.company-info {
  background: rgba(215, 215, 215, 1);
  background: -moz-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(215, 215, 215, 1)),
    color-stop(49%, rgba(209, 209, 209, 1)),
    color-stop(64%, rgba(219, 219, 219, 1)),
    color-stop(100%, rgba(226, 226, 226, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(215, 215, 215, 1) 0%,
    rgba(209, 209, 209, 1) 49%,
    rgba(219, 219, 219, 1) 64%,
    rgba(226, 226, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7d7d7', endColorstr='#e2e2e2', GradientType=0 );
}

.creation-page {
  width: 100vw;
  height: 100vh;
  background: #f8f8f8;
}

.title-view {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.creation-title {
  margin-left: 20px;
  font-size: 1.7em;
  font-weight: bold;
  font-family: 'Monserrat';
}

.creation-form {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.div-fixed {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
}

.rdtCount {
  border: 0px !important;
}

.btn.btn-input {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-top: 10px !important;
}

.btn-input {
  width: 100%;
  min-height: 50px !important;
  margin: 0 !important;
  background-color: rgba(159, 157, 157, 0.29) !important;
  color: #373737 !important;
  color: #373737 !important;
  font-size: 1.05em !important;
  font-weight: bold !important;
}

.custom-tag {
  height: 50px;
  width: 140px;
  border-radius: 30px;
  border: 2px solid #60666e;
  margin: 5px 5px;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tag-active {
  background: #60666e;
}

.tag-label {
  color: #60666e;
  font-size: 1em;
  text-align: center;
}

.label-active {
  color: #ffffff;
}

.creation-footer {
  margin: 10px 20px;
  display: flex;
  justify-content: center;
}

.file-input-hidden {
  display: none;
}

.dropdown-arrow {
  width: 20px !important;
  height: auto;
}

.dropdown-label.bold {
  font-weight: bold;
}

.dropdown-options {
  max-height: 200px !important;
  overflow-y: scroll;
}

.button-shaddow {
  -webkit-box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  box-shadow: 14px 4px 21px 5px rgba(0, 0, 0, 0.13);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  padding-left: 10px !important;
}

.modal-title {
  color: #444b54;
  font-weight: bold;
  font-size: 1.1em !important;
  text-align: center;
}

.modal-close-btn {
  cursor: pointer;
}

.notice-header {
  justify-content: center !important;
}

.notice-footer {
  justify-content: flex-end !important;
}

.popover-title {
  margin-bottom: 20px;
  color: #004188;
  font-weight: bold;
}

.popover-text {
  margin-bottom: 5px;
}

.text-underline {
  text-decoration: underline;
}

.label-clickable {
  cursor: pointer;
}
