.forgot-content {
  border-radius: 0px !important;
  border: 0px solid !important;
}

.forgot-page {
  @media screen and (max-width: 906px) {
    .bg-image {
      background-size: cover;
      height: 80vh;
    }
  }

  .bg-image {
    background-image: url(../../../../assets/img/auth_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card-plain {
    width: 100% !important;
  }

  .content-footer {
    height: 25vh;
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 2% !important;
    border-width: 1px;
    background-color: #fff !important;
    border-color: #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .on-secondary {
    color: white;
    font-weight: 600;
  }

  h2,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bottom-cgu {
    max-width: 380px;
    height: 40px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
