.status-tag {
  height: 40px;
  width: 173px;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.tag-success {
  background: rgba(139, 209, 25, 1);
  background: -moz-linear-gradient(
    top,
    rgba(139, 209, 25, 1) 0%,
    rgba(94, 174, 2, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(139, 209, 25, 1)),
    color-stop(100%, rgba(94, 174, 2, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(139, 209, 25, 1) 0%,
    rgba(94, 174, 2, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(139, 209, 25, 1) 0%,
    rgba(94, 174, 2, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(139, 209, 25, 1) 0%,
    rgba(94, 174, 2, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(139, 209, 25, 1) 0%,
    rgba(94, 174, 2, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bd119', endColorstr='#5eae02', GradientType=0 );
}

.tag-rejected {
  background: rgba(254, 96, 106, 1);
  background: -moz-linear-gradient(
    top,
    rgba(254, 96, 106, 1) 0%,
    rgba(227, 1, 16, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(254, 96, 106, 1)),
    color-stop(100%, rgba(227, 1, 16, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(254, 96, 106, 1) 0%,
    rgba(227, 1, 16, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(254, 96, 106, 1) 0%,
    rgba(227, 1, 16, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(254, 96, 106, 1) 0%,
    rgba(227, 1, 16, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(254, 96, 106, 1) 0%,
    rgba(227, 1, 16, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe606a', endColorstr='#e30110', GradientType=0 );
}

.tag-confirmed_with_pj,
.tag-ongoing {
  background: rgba(51, 182, 222, 1);
  background: -moz-linear-gradient(
    top,
    rgba(51, 182, 222, 1) 0%,
    rgba(0, 124, 196, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(51, 182, 222, 1)),
    color-stop(100%, rgba(0, 124, 196, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(51, 182, 222, 1) 0%,
    rgba(0, 124, 196, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(51, 182, 222, 1) 0%,
    rgba(0, 124, 196, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(51, 182, 222, 1) 0%,
    rgba(0, 124, 196, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(51, 182, 222, 1) 0%,
    rgba(0, 124, 196, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33b6de', endColorstr='#007cc4', GradientType=0 );
}

.tag-not_confirmed,
.tag-in_progress,
.tag-desactive {
  background: rgba(183, 183, 183, 1);
  background: -moz-linear-gradient(
    top,
    rgba(183, 183, 183, 1) 0%,
    rgba(108, 108, 108, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(183, 183, 183, 1)),
    color-stop(100%, rgba(108, 108, 108, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(183, 183, 183, 1) 0%,
    rgba(108, 108, 108, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(183, 183, 183, 1) 0%,
    rgba(108, 108, 108, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(183, 183, 183, 1) 0%,
    rgba(108, 108, 108, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(183, 183, 183, 1) 0%,
    rgba(108, 108, 108, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33b6de', endColorstr='#007cc4', GradientType=0 );
}

.tag-confirmed_without_pj {
  background: rgba(228, 126, 0, 1);
  background: -moz-linear-gradient(
    top,
    rgba(228, 126, 0, 1) 0%,
    rgba(228, 126, 0, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(228, 126, 0, 1)),
    color-stop(100%, rgba(228, 126, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(228, 126, 0, 1) 0%,
    rgba(228, 126, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(228, 126, 0, 1) 0%,
    rgba(228, 126, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(228, 126, 0, 1) 0%,
    rgba(228, 126, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(228, 126, 0, 1) 0%,
    rgba(228, 126, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33b6de', endColorstr='#007cc4', GradientType=0 );
}

.status-label {
  color: #fff;
}
