.demande-title-text {
  color: #444b54;
  font-weight: bold;
  font-size: 1.8em !important;
}

.requests-list {
  padding: 20px 30px;
}

.request-table {
  display: flex;
  align-items: center;
}
