h5 {
  font-size: 1em !important;
  margin-bottom: 0px !important;
}

.details-content-view {
  margin-top: 17%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.details-content-key {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}

.details-content-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 50%;
}

.detail-status-tag {
  margin-bottom: 15px;
}

.detail-key {
  color: #9aa2a8;
  font-size: 1em;
}

.details-content-value h5 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.details-input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
}

.details.react-select,
.details.react-select__control {
  width: 100% !important;
  height: 50px !important;
  border-radius: 8px !important;
}

.details.react-select__control--is-focused,
.details.react-select__control--is-focused:hover {
  border: 1px solid #e3e3e3 !important;
}

.file-input-view {
  cursor: pointer;
  margin-left: 10px;
}

.file-icon {
  width: 20px;
  height: auto;
  object-fit: contain;
}

.file-input-hidden {
  display: none;
}

.logo-edit {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.details-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.info-view {
  display: flex;
  min-height: 30px;
  align-items: center;
}

.info-view h5 {
  font-weight: bold;
  font-size: 0.9em !important;
}

.info-value {
  margin-left: 5px;
  color: #004083;
  font-weight: bold;
  font-size: 0.9em !important;
}

.download-l {
  cursor: pointer;
}

.info-key {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 30%;
  color: #506f90;
}

.info-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.info-value h5 {
  margin-bottom: 0px !important;
  margin-right: 20px;
}

.details-logo {
  width: 3;
}

.row-info {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
