.landing-content {
  border-radius: 0px !important;
  border: 0px solid !important;
  padding-top: 5vh;
}

.landing {
  @media screen and (max-width: 906px) {
    .bg-image {
      background-size: cover;
      height: 80vh;
    }
  }

  .bg-image {
    background-image: url(../../assets/img/home_bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .btn-round {
    width: 68% !important;
  }

  .card-plain {
    width: 100% !important;
  }

  .card-login {
    width: 100% !important;
  }
}

.on-secondary {
  color: white;
  font-weight: 600;
  max-width: 300px;
}

h2,
h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.portal-title {
  margin-bottom: 0px !important;
  font-size: 3em !important;
}

.Copyright {
  position: absolute;
  bottom: 73px !important;
  left: 150px !important;
}
.certification {
  bottom: 60px !important;
  position: absolute;
  left: 43px;
}
@media screen and (max-width: 700px) {
  // .certification {
  //   bottom: 50px !important;
  //   position: absolute;
  //   right: 30px !important;
  // }
  .Copyright {
    bottom: 60px !important;
  }
}
